<template>
  <v-card tile :elevation="0" class="crud-box list-devices">
    <h1 class="mx-4">Manage Devices</h1>
    <v-layout wrap class="px-4 pt-4">
<!--      <div style="overflow: visible">
        <v-btn color="success" class="mr-2" @click="onClickNew">
          <v-icon>mdi-plus</v-icon>
          New
        </v-btn>
        <v-btn color="primary" outlined class="mr-2" @click="onClickImport">
          <v-icon>mdi-database-import</v-icon>
          Import
        </v-btn>
        <v-btn color="primary" outlined class="mr-2" @click="onClickExport">
          <v-icon>mdi-database-export</v-icon>
          Export
        </v-btn>
        <v-btn color="error" v-if="pickedItems.length > 0" class="mr-2" @click="onClickDeleteAll">
          <v-icon>mdi-delete</v-icon>
          Delete Items
        </v-btn>
      </div>-->
      <!---->
      <v-layout wrap>
        <v-text-field style="max-width: 189px" dense outlined @keyup.enter="doSearch" class="mr-2" label="Search Tên"
                      v-model="searchFields.name.value"/>
        <v-text-field style="max-width: 189px" dense outlined @keyup.enter="doSearch" class="mr-2" label="Search Imei"
                      v-model="searchFields.imei.value"/>
        <v-select style="max-width: 189px" dense outlined @keyup.enter="doSearch" class="mr-2" label="Search Trạng thái" :items="listStatus"
                      v-model="searchFields.status.value"/>
      </v-layout>
      <v-btn color="success" @click="() => { dialog.generateCode = true}" class="mr-2">
        Generate Code
      </v-btn>
      <v-btn color="primary" @click="doSearch">
        <v-icon>mdi-magnify</v-icon>
        Search
      </v-btn>
    </v-layout>

    <!--card content-->
    <v-card tile :elevation="1" :disabled="tblLoading" outlined class="block-tbl mx-4">
      <v-data-table :headers="tblHeader" :items="listItems" :server-items-length="tblPagination.total"
                    :loading="tblLoading"
                    :footer-props="tblConfig.footerProps" @dblclick:row="onDbClickRow" :options.sync="tblOptions"
                    :loading-text="tblConfig.text.loading" v-model="pickedItems" :show-select="tblConfig.showSelect">
        <template v-slot:no-data>
          {{ tblConfig.text.noItem }}
        </template>

        <template v-slot:item.no="{item}">
          {{ getIndexOfItem(item) }}
        </template>
        <template v-slot:item.expired_date="{item}">
          {{ item.expired_date.substr(0,10).split('-').reverse().join('-') }}
        </template>

        <template v-slot:item.status="{ item }">
          <span class="font-weight-bold"
            :class="{'success--text': item.status === CONSTANTS.DEVICE_STATUS.OPEN, 'error--text': item.status === CONSTANTS.DEVICE_STATUS.CLOSE}">
            {{ item.status === CONSTANTS.DEVICE_STATUS.OPEN ? 'Active' : 'Close' }} </span>
        </template>

        <template v-slot:item.actions="props">
          <v-icon small color="primary" @click="onClickDetail(props)" class="mr-2">mdi-view-list</v-icon>
          <v-icon small color="secondary" @click="onClickEdit(props)" class="mr-2">mdi-pencil</v-icon>
          <v-icon small color="error" @click="onClickDelete(props)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog.generateCode" :width="400">
      <GenerateCode :onCancel="()=>{dialog.generateCode=false}"></GenerateCode>
    </v-dialog>

    <v-dialog v-model="dialog.create" :width="800">
      <CreateItem :onCreated="onCreated" :onCancel="()=>{dialog.create=false}"></CreateItem>
    </v-dialog>
    <v-dialog v-model="dialog.detail" :width="'60%'">
      <DetailItem :selectedItem="selectedItem" :onClose="()=>{dialog.detail=false}"></DetailItem>
    </v-dialog>
    <v-dialog v-model="dialog.update" :width="800">
      <UpdateItem :onUpdated="onUpdated" :selectedItem="selectedItem"
                  :onCancel="()=>{dialog.update=false}"></UpdateItem>
    </v-dialog>
    <v-dialog v-model="dialog.delete" :width="400">
      <DeleteItem :idsToDelete="idsToDelete" :onDeleted="onDeleted" :onCancel="()=>{dialog.delete=false}"></DeleteItem>
    </v-dialog>
    <v-dialog v-model="dialog.import" :width="400">
      <ImportItem :onImported="onImported" :onCancel="()=>{dialog.import=false}"></ImportItem>
    </v-dialog>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import GenerateCode from "./GenerateCode";
import CreateItem from "./Create";
import DetailItem from "./Detail";
import UpdateItem from "./Update";
import DeleteItem from "./Delete";
import ImportItem from "./Import";
import indexMixin from './indexMixin';

export default {
  name: 'ManageDevices',
  components: {GenerateCode, CreateItem, DetailItem, UpdateItem, DeleteItem, ImportItem},
  mixins: [indexMixin],
  data() {
    return {
      searchFields: {
        name: {
          value: '',
          type: 'like',
        },
        imei: {
          value: '',
          type: 'like',
        },
        status: {
          value: '',
          type: 'like',
        },
      },
      searchWith: 'user',
      searchWithCount: '',
      tblHeader: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
          groupable: false,
          width: '55px',
          align: 'start',
        },
        {
          text: 'Đại lý',
          value: 'user.name',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Tên',
          value: 'name',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Imei',
          value: 'imei',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Ngày hết hạn',
          value: 'expired_date',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Trạng thái',
          value: 'status',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          groupable: false,
          width: '111px',
          align: 'center',
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'device/all',
      tblPagination: 'device/pagination',
      userById: 'user/getById',
      listStatus: 'device/listStatus',
    }),
  },
  methods: {
    ...mapActions({
      getListItems: 'device/get',
      exportListItems: 'device/export',
    }),
  }
}
</script>
