<template>
  <v-card class="mx-auto pa-5">
    <v-card-title class="mx-0 px-0">Enter your device Emei</v-card-title>
    <v-row>
      <v-col cols="12">
        <v-text-field dense outlined label="Imei" v-model="imei"/>
      </v-col>
      <v-col cols="12">
        <v-textarea rows="6" dense outlined label="Code" v-model="code" readonly />
      </v-col>
    </v-row>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="processAdd">Generate Code</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'GenerateCode',
  props: ['onCreated', 'onCancel'],
  data: () => ({
    imei: '',
    code: ''
  }),
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({
      generateCode: 'device/generateCode'
    }),
    processAdd() {
      this.generateCode(this.imei).then((res) => {
        this.code = res.data?.data
      });
    }
  }
}
</script>
